.live-list a {
  display: flex;
  width: 25%;
}
.live-box {
    padding: 11px;
}
.live-box img{
    display: block;
    width: 100%;
    max-width: 237px;
    height: auto;
}

.home-page {
    width: 100%;
    max-width: 1445px;
}
.home-container {
  padding: 26px;
}
.home-main {
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    overflow: hidden;
    margin-right: 26px;
}

.right-col {
    width: 288px;
    background-color: white;
    border-radius: 12px;
}

.right-col-header {
    height: 83px;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #B75CFF 95.46%);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.right-col-header .title {
    font-size: 22px;
    line-height: 30.8px;
    color: white;
}
.right-col-content {
  padding-left: 12px;
  padding-right: 12px;
}

@media (max-width: 960px) {
  .live-list {
    justify-content: center;
  }
  .live-list a {
    width: auto;
  }
  .home-container {
    flex-direction: column;
  }
  .home-main {
    margin-right: 0;
    margin-bottom: 24px;
  }
  .right-col {
    width: 100%;
  }
}
