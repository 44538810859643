.charge-page {
    max-width: 1388px;
}
.charge-container {
    padding: 88px;
}
.pay-page {
    max-width: 1388px;
    min-width: 1024px;
}
.charge-notice {


    left: 88px;
    top: 592px;

    background: #FFFDEE;
    border-radius: 8px;
    padding: 25px;
}

.charge-notice p {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.charge-row {
    margin-top: 40px;
}

.charge-submit {
    width: 157px;
    height: 58px;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 12px;
    display: flex;
    color:white;
    justify-content: center;
    align-items: center;
}

.charge-notice {
    margin-top: 26px;
}


.charge-type {
    width: 106px;
    height: 83px;
    left: 0px;
    top: 0px;

    background: #F8F8F8;
    border-radius: 12px;
    margin: 9px;
    color: #ababab;
}

.charge-type.active {
    width: 106px;
    height: 83px;
    left: 0px;
    top: 0px;

    background: rgba(254, 210, 255, 0.3);
    border: 2px solid #FC66FF;
    border-radius: 12px;
    margin: 9px;
    color: #FD66EE;
}

.charge-list {
  display: flex;
}
@media (max-width: 960px) {
  .charge-container {
    padding: 24px;
  }
  .charge-list {
    flex-direction: column;
  }
}
