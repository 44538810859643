.gift-panel{
    margin-top: -8px;
    height: 156px;
    /* background-color: white; */
    background: linear-gradient(91.82deg, #410093 5.85%, #7D0000 104.58%);
    border-radius: 8px 8px 0px 0px;
}

.gift-container {
    min-width: 104px;
    overflow: hidden;
    position: relative;
}

.gift-content {
    position: absolute;
    left: 0;
}
.gift img {
    width: 64px;
    height: 64px;
}
.gift {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 107px;
    width: 104px;
    flex-direction: column;
    color: white;
}

.gift-control {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
}

.gift-desc {
    width: 200px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gift.active {
    border:#FD0000 solid 4px;
}
.active-gift {
    color:#FFF000;
}

.gift-send {
    color:white;
    width: 75px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    background: linear-gradient(91.22deg, #FC70FF 2.91%, #FF5C5C 95.46%);
    border-radius: 12px;

}