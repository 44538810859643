.header {
    position: relative;
    padding:0px 57px;
    background-color: white;
    width: 100%;
    justify-content: space-between;
}

.header .logo{
    width: 170px;
    height: 38px;
    margin-right: 80px;
}

ul {
    list-style-type: none;
}

li {
    min-width: 69px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
}

li.active {
    color:#FF5C5C
}

.nav {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.nav-menu {
  display: flex;
}

.header-sign {
  display: flex;
}

.login {
    display: flex;
    width: 69px;
    height: 34px;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 12px;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    line-height: 21px;
    margin-right: 16px;
}

.xs-divider {
  display: none;
}
.xs-shown {
  display: none !important;
}
.xs-hidden {
  display: flex !important;
}

@media (max-width: 960px) {
  .header {
    padding-right: 24px;
  }
  .nav {
    position: absolute;
    z-index: 99;
    top: 100%;
    right: 0;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 12px;
  }
  .nav-menu {
    flex-direction: column;
  }
  .nav-menu li:not(:last-child) {
    margin-bottom: 6px;
  }
  .header-sign {
    flex-direction: column;
  }
  .login {
    margin-right: 0;
    margin-bottom: 6px;
  }
  .xs-divider {
    width: 100%;
    display: flex;
  }
  .xs-shown {
    display: flex !important;
  }
  .xs-hidden {
    display: none !important;
  }
}

.register {
    border: 1px solid #FC66FF;
    border-radius: 12px;
    width: 69px;
    max-width: 108px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    line-height: 21px;
}

.close-button {
    width: 54px;
    height: 54px;
    position:absolute;
    right:10px;
    top:10px;
}

.close-button img {
    width: 14px;
    height: 14px;
}

.h-s-active {
    color: #FF5C5C;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

.h-s-inactive{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color:#ACACAC;
}
.h-s-top {
    height: 72px;
    align-items: center;
    padding-left: 32px;
}

.register-submit {
    width: 341px;
    height: 43px;
    left: 0px;
    top: 0px;

    /* Linear */
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 8px;
}

.register-input {
    border: 1px solid #DCDCDC;
    border-radius: 8px;
    padding:11px;
    height: 43px;

}
.register-form-row{
    margin:8px 32px;
}

.register-captcha {
    width: 111px;
    height: 43px;
    left: 0px;
    top: 0px;

    /* Linear */
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 8px;
}