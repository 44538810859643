.show-container {
  padding: 26px;
}

.show-main {
  padding: 20px;
  border-radius: 12px;
  background-color: white;
}

.left-panel {
    /*height: 736px;*/
}

.show-video {
  width: 100%;
  height: 588px;
}

.player {
  width: 100%;
  height: 100%;
}

.player video {
  object-fit: cover;
}

.chat {
    width: 460px;
}

.chat-input-form {
    height: 64px;
    display: flex;
    align-items: center;
    padding-left: 22px;
}

.chat-input {
    width: 282px;
    height: 37px;
    border: 1px solid #DCDCDC;
    border-radius: 8px;
    padding-left: 8px;
}

.chat-send {
    left: 0%;
    right: 0%;
    top: -2.7%;
    bottom: 0%;
    width: 75px;
    height: 38px;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    margin-left: 6px;
}

.chat-content {
    padding:36px;
    overflow: scroll;
}

@media (max-width: 960px) {
  .show-container {
    padding: 6px;
  }
  .show-panel {
    flex-direction: column;
  }
  .show-video {
    height: 360px;
  }
  .chat {
    width: auto;
  }
  .chat-input {
    width: auto;
  }
  .chat-content {
    padding: 12px;
  }
  .chat-input-form {
    padding-left: 12px;
  }
}