.debug {
  /* border:red solid 1px; */
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fx-box {
  display: flex;
}

.fx-jc-c {
  justify-content: center;
}

.fx-ai-c{
  align-items: center;
}

.fx-jc-b{
  justify-content: flex-start;
}

.fx-jc-b{
  justify-content: flex-end;
}


.fx-1{
  flex:1
}

.fx-fd-r{
  flex-direction: row;
}
.fx-fd-c{
  flex-direction: column;
}



.header {
  height: 97px;
}

.fx-wrap {
  flex-wrap: wrap;
}

.color-white {
  color:white;
}

.color-fu {
  color:#FD66EE;
}

.font-size13 {
  font-size: 13px;
}

.font-size17 {
  font-size: 17px;
}

.font-size26 {
  font-size: 26px;
}

.btn {
  cursor: pointer;
}


.section {
  border-radius: 12px;
  margin: 26px;
  padding: 20px;
  background: #FFFFFF;
}


.section-header{
  height: 80px;
  line-height: 36.4px;
  font-size: 26px;
  margin-left: 11px;
}

.section-header .title {
  margin-left: 10px;
}

.header-icon {
  width: 47px;
  height: 47px;
}
